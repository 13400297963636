<template>
    <ui-modal ref="activeModal" id="addApp" title="Two Step Verification" @hidden="clearForm">
        <application-form ref="applicationForm" />
        <template #footer>
            <ui-button variant="ligth" @click="hide">Close</ui-button>
            <ui-button :loading="saving" variant="primary" @click="addApp">Add</ui-button>
        </template>
    </ui-modal>
</template>

<script>
import ApplicationForm from '@/components/twofactorauth/ApplicationForm';
import UiButton from '@/components/ui/buttons/Button';
import UiModal from '@/components/ui/Modal';

export default {
    name: 'ActiveModal',
    components: {
        ApplicationForm,
        UiButton,
        UiModal,
    },
    data() {
        return {
            saving: false,
        }
    },
    methods: {
        show() {
            this.$refs.activeModal.show();
            this.$refs.applicationForm.generateOtp()
        },
        hide() {
            this.$refs.activeModal.hide();
        },
        clearForm() {
            this.$refs.applicationForm.clearForm();
        },
        async addApp() {
            try {
                this.saving = true;

                if (!await this.$refs.applicationForm.validate()) {
                    return;
                }

                const { data } = await this.$axios.post('/_/otp/verify', this.$refs.applicationForm.formData);
                
                this.$emit('appAdded', data);
                this.$refs.activeModal.hide();
                this.$toast.success('Two-factor authentication successfully enabled');
            } catch(error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.saving = false;
            }
        },
    },
};
</script>
