<template>
    <div class="address-list">
        <ui-list-group-skeleton v-if="loading" :rows="2" />
        <list v-else>
            <list-item v-for="auth in auths" :key="auth.nameId">
                <auth-item :auth="auth" :show-delete-button="auths.length > 1" @deleteApp="showDeletemodal" />
            </list-item>
        </list>
        <active-modal ref="activeModal" @appAdded="appAdded" />
        <desactive-modal ref="desactivateModal" @appDeleted="appDeleted" />
    </div>
</template>

<script>
import ActiveModal from '@/components/twofactorauth/ActiveModal.vue';
import AuthItem from '@/components/twofactorauth/AuthItem.vue';
import DesactiveModal from '@/components/twofactorauth/DesactiveModal.vue';
import List from '@/components/ui/list/List';
import ListItem from '@/components/ui/list/ListItem';
import UiListGroupSkeleton from '@/components/ui/skeleton/ListGroupSkeleton';

export default {
    components: {
        ActiveModal,
        AuthItem,
        DesactiveModal,
        List,
        ListItem,
        UiListGroupSkeleton,
    },
    data() {
        return {
            loading: false,
            auths: [],
        };
    },
    mounted() {	
        this.getAllAuth();
    },
    methods: {
        showAddModal() {
            this.$refs.activeModal.show();
        },
        showDeletemodal(app) {
            this.$refs.desactivateModal.show(app);
        },
        appAdded(app) {
            this.auths.push(app);
        },
        appDeleted(app) {
            const search_index = this.auths.findIndex(item => item.secret === app.secret);
            this.auths.splice(search_index, 1);
        },
        async getAllAuth() {
            try {
                this.loading = true;
                const { data } = await this.$axios.get('/_/otp/all');
                this.auths = data;
            } catch(error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>