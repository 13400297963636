<template>
    <div class="auth-item w-100 d-flex align-items-center justify-content-between">
        <h6 class="auth-item__title">
            <span class="auth-item__title-icon fas fa-mobile-alt"></span>
            {{ auth.name }}
        </h6>
        <ui-button v-if="showDeleteButton" class="auth-item__button" @click="$emit('deleteApp', auth)">
            <span class="fas fa-trash"></span>
        </ui-button>
    </div>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button.vue';

export default {
    components: { UiButton },
    props: {
        auth: {
            type: Object,
            default: null,
            required: true,
        },
        showDeleteButton: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    data() {
        return {
            deleting: false,
            loading: false,
        };
    },
};
</script>

<style lang="scss">
.auth-item {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;

    &__actions {
        align-items: center;
        display: flex;
        margin-left: au;
    }

    &__title {
        color: $general-black;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0;

        span {
            color: $ecart-primary;
            font-size: 1.2rem;
        }
    }

    &__button {
        background-color: transparent !important;
        border: none !important;
        color: $ecart-secondary-400 !important;
    }
}
</style>
