<template>
    <div class="profile">
        <p class="profile__account-number mt-3">
            Here you can view and configure the information related to your account.
        </p>
        <div class="row">
            <div class="col-md-6 mb-3">
                <ui-card title="Personal information" description="Update your personal account information">
                    <div v-if="!account" class="py-5 text-center">
                        <custom-spinner class="spinner-border-md" />
                    </div>
                    <template v-else>
                        <form-admin
                            ref="formAdmin"
                            :default-data="account"
                            :disabled-inputs="['email', 'role', 'profile']"
                            :hidden-inputs="['active', 'scopes']"
                        />
                        <div class="d-flex justify-content-between">
                            <ui-button
                                id="reset-password-button"
                                variant="link"
                                :disabled="updatingPassword"
                                @click="$refs.passwordModal.show()"
                            >
                                Update password
                            </ui-button>
            
                            <ui-button
                                :loading="updating"
                                variant="primary"
                                @click="submit"
                            >
                                Update
                            </ui-button>
                        </div>
                        <b-modal ref="passwordModal" title="Update password" @hidden="this.$refs.formPassword.reset()">
                            <form>
                                <form-password ref="formPassword" show-password-confirmation />
                            </form>
                            <template #footer>
                                <ui-button variant="light" :disabled="updatingPassword" @click="closeModal">
                                    Close
                                </ui-button>
                                <ui-button variant="primary" :loading="updatingPassword" @click="updatePassword">
                                    Confirm
                                </ui-button>
                            </template>
                        </b-modal>
                    </template>
                </ui-card>
            </div>
            <div class="col-md-6 mb-3">
                <ui-card title="Two Factor Authentication" description="Add an extra layer of security to your account by requesting access with your smartphone when you log in.">
                    <template #actions>
                        <ui-button variant="link" icon="fas fa-plus" @click="$refs.authList.showAddModal()">
                            Add
                        </ui-button>
                    </template>
                    <auth-list ref="authList" />
                </ui-card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import AuthList from '@/components/twofactorauth/AuthList.vue';
import CustomSpinner from '@/components/ui/CustomSpinner.vue';
import FormAdmin from '@/components/forms/FormAdmin.vue';
import FormPassword from '@/components/forms/FormPassword.vue';
import UiButton from '@/components/ui/buttons/Button'
import UiCard from '@/components/ui/Card.vue';

export default {
    components: {
        AuthList,
        CustomSpinner,
        FormAdmin,
        FormPassword,
        UiButton,
        UiCard,
    },
    data() {
        return {
            updating: false,
            updatingPassword: false,
        };
    },
    computed: {
        ...mapGetters('accounts', ['fullName']),
        ...mapState('accounts', ['account']),
    },
    methods: {
        closeModal() {
            this.$refs.passwordModal.hide();
            this.$refs.formPassword.reset();
        },
        async updatePassword() {
            const formPassword = this.$refs['formPassword']
            const validated = await formPassword.validate();

            if (!validated) return

            try {
                this.updatingPassword = true;

                await this.$axios.put('/_/admins/me', {
                    password: formPassword.formData.password,
                });
                
                this.closeModal();
                this.$toast.success('Password updated successfully.');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.updatingPassword = false;
            }
        },
        async submit() {
            const formAdmin = this.$refs['formAdmin']
            const validated = await formAdmin.validate();

            if (!validated) return;

            try {
                this.updating = true;

                await this.$axios.put(`/_/admins/me`, formAdmin.formData);

                await this.$store.dispatch('accounts/getAccount')

                this.$toast.success('Your information was successfully updated');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.updating = false;
            }
        },
    },
};
</script>
