<template>
    <ui-modal ref="desactivateModal" title="Remove app" centered @hidden="clearModal">
        <p>Are you sure to remove this item from the list of applications for two-factor authentication?</p>
        <p class="text-muted mb-0">Remember that you must also remove this record from the application you use to authenticate.</p>
        <template #footer>
            <ui-button :disabled="saving" @click="hide">Cancel</ui-button>
            <ui-button :loading="saving" variant="danger" @click="remove">Confirm</ui-button>
        </template>
    </ui-modal>
</template>

<script>
import UiModal from '@/components/ui/Modal.vue';
import UiButton from '@/components/ui/buttons/Button.vue';

export default {
    name: 'DesactiveModal',
    components: { UiButton, UiModal},
    data() {
        return {
            saving: false,
            app: null,
        }
    },
    methods: {
        show(app) {
            this.app = app;
            this.$refs.desactivateModal.show();
        },
        hide() {
            this.$refs.desactivateModal.hide();
        },
        clearModal() {
            this.app = null;
        },
        async remove(){
            try {
                this.saving = true;

                await this.$axios.delete(`/_/otp/${this.app.secret}`);
                
                this.$toast.success('App deleted successfully');
                this.$emit('appDeleted', this.app);
                this.hide();
            } catch(error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.saving = false;
            }
        }
    }
}
</script>
